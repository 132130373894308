import { GlobalPage } from "./page/GlobalPage/GlobalPage";
import { ThreekitProvider } from "@threekit-tools/treble/dist";
import { useLocation } from "react-router-dom";

import "./index.css";
import { useEffect } from "react";

function App() {
  const { pathname } = useLocation();

  let search = window.location.search;
  let origin = window.location.origin;
  let searchParams = new URLSearchParams(search);
  let assetId = searchParams.get("assetId");

  const href = `${origin}/?assetId=cf30de6d-099d-471b-aba9-c86c07cad252`;

  const projects = {
    credentials: {
      preview: {
        publicToken: "3afcbe1a-c7fe-4f44-a9fc-2199b324dfa0",
        orgId: "e5483d15-c235-4ab3-b18f-c2b447d82dde",
      },
    },
    products: {
      preview: {
        assetId: assetId ? assetId : "cf30de6d-099d-471b-aba9-c86c07cad252",
      },
    },
  };

  const threekitEnv = "preview";
  useEffect(() => {}, [pathname]);
  return (
    <>
      {assetId ? (
        <ThreekitProvider project={projects} threekitEnv={threekitEnv}>
          <GlobalPage />
        </ThreekitProvider>
      ) : (
        <div className="title">
          Set assetId in url, example:
          <br />
          <a href={href}>{href}</a>
        </div>
      )}
    </>
  );
}

export default App;
