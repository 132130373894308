export default {
  //     //  (optional): determines whether to use the 3D Player (webgl) or
  //     //  the 2D Player (image).
  display: "webgl",
  //     //  The caching options for the player. It contains the maxAge
  //     //  and scope for assets caching.
  //     cache,
  //     //  (optional): Determines if we render the default Threekit
  //     //  configurator.
  showConfigurator: true,
  //     //  (optional): An override for the configuration to initialize
  //     //  our asset with.
  //     initialConfiguration: {},
  //     //  (optional): parameter to display a snapshot while the player
  //     //  is loading. Default value is false
  //     showLoadingThumbnail: true | false,
  //     //  (optional): Determines if we show the progress bar during
  //     //  load. Default value is true.
  //     showLoadingProgress: true | false,
  //     //  Takes a callback as its value. The callback's only argument
  //     //  is a number, representing the progress ratio ( from 0.0
  //     //  to 1.0 ). The callback will be called whenever the loading
  //     //  progresses. The progress ratio is only approximate.
  //     onLoadingProgress: progress =>
  // console.log(`Progress ${parseInt(progress * 100)}`),
  //     //  (optional): Parameter to show/hide the built-in AR Button.
  //     //  Default value is false.
  //     showAR,
  //     //  (optional): Parameter to show/hide the built-in Share
  //     //  Button. Default value is false.
  //     showShare,
  //     //  (optional): toggles vertical orbit on mobile devices on or
  //     //  off. Default value is false.
  //     allowMobileVerticalOrbit: true | false,
  //         //  (optional): Override organization's compression setting for
  //         //  renders in 2D player.
  //         compression,
};
